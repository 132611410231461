import React from "react";
import "video-react/dist/video-react.css";
import "tachyons";

import videoDataArray from "./data/videoData.js";

import NavBar from "./components/NavBar.js";
import VideoPlayer from "./components/VideoPlayer.js";

function App() {
  console.log("submit videos 2 info@trebuchet.engineering");
  const renderVideos = () => {
    return videoDataArray.map((video) => {
      return <VideoPlayer name={video.name} path={video.path} year={video.year} />;
    });
  };
  const audioPlayback = (arr) => {
    const audioEl = document.getElementsByClassName("audio-element")[0];
    arr === "play" ? audioEl.play() : audioEl.pause();
  };

  return (
    <div className="white bg-black">
      <div className="f4 hot-pink pa2">submit videos 2 info@trebuchet.engineering </div>
      <NavBar audioPlayback={audioPlayback} />
      <div className="pt5"> {renderVideos()}</div>
      <audio className="audio-element">
        <source src="/songs/lol.mp3"></source>
      </audio>
    </div>
  );
}

export default App;
