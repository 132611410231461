import React from "react";
import { Player } from "video-react";

export default function VideoPlayer({ path, year, name }) {
  return (
    <div className="pv4 w-70 center">
      <h1 className="tc">{year}</h1>
      <h3 className="tc">{name}</h3>
      <Player>
        <source src={`/videos/${path}`} />
      </Player>
    </div>
  );
}
