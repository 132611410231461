const videoData = [
	{
		path: "90smontage.mp4",
		name: "90s items montage",
		year: 1999,
	},
	{
		path: "star-wars-rape-2.mp4",
		name: "Stars Wars....look what they did",
		year: 2016,
	},
	{
		path: "vidya-nos.mp4",
		name: "home from school",
		year: 2002,
	},
	{
		path: "10amChildren.mp4",
		name: "Rave Days",
		year: 2000,
	},
	{
		path: "2000prom.mp4",
		name: "the last gen b4 the GWOT and 9/11",
		year: 2000,
	},
	{
		path: "america-rip.mp4",
		name: "game over",
		year: 2017,
	},
	{
		path: "prom93.mp4",
		name: "no selfies",
		year: 1993,
	},
	{
		path: "lan-party-better-off-alone.mp4",
		name: "Lan-party ",
		year: 2003,
	},
	{
		path: "og-youtube.mp4",
		name: "youtube before clickbait/vblog",
		year: 2005,
	},
	{
		path: "star-wars-raped.mp4",
		name: "The rape of Star Wars",
		year: 2018,
	},
	{
		path: "highschool-walkthrough.mp4",
		name: "first period, april",
		year: 1990,
	},
	{
		path: "tv-bump.mp4",
		name: "bump the TV",
		year: 2001,
	},
	{
		path: "80-hs.mp4",
		name: "80s high and fly",
		year: 1986,
	},
];

export default videoData.sort((a, b) => (a.year > b.year ? 1 : -1));
