import React, { useState } from "react";

export default function NavBar({ audioPlayback }) {
  const [play, setPlay] = useState(false);

  const handleAudio = () => {
    play ? audioPlayback("stop") : audioPlayback("play");
    setPlay(!play);
  };

  return (
    <div className="fixed  bg-black hover-bg-white hover-black white  w-100 tc">
      <h5 onClick={() => handleAudio()}>{play ? "stop me" : "play me"}</h5>
    </div>
  );
}
